/* eslint-disable import/extensions */
import './styled/fonts';
import '@fontsource/red-hat-display/400.css';
import '@fontsource/red-hat-display/500.css';
import '@fontsource/red-hat-display/700.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/400.css';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/toast';
// eslint-disable-next-line import/no-unresolved
import { Fonts, PrivateRender, theme as ThemeData } from '@react-ib-mf/style-guide-ui';
import { AvatarChakra } from '@wow/avatar';
import { ButtonChakra as Button } from '@wow/button';
import { CheckboxChakra } from '@wow/checkbox';
import { ModalChakra } from '@wow/modal';
import { SkeletonChakra } from '@wow/progress-indicator';
// eslint-disable-next-line import/no-unresolved
import { themeChakra } from '@wow/themes';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import App from './App';

const { ToastContainer } = createStandaloneToast();

const theme = extendTheme({
  ...ThemeData,
  textStyles: {
    ...themeChakra.textStyles,
  },
  components: {
    Button,
    Modal: ModalChakra,
    Checkbox: CheckboxChakra,
    Avatar: AvatarChakra,
    Skeleton: SkeletonChakra,
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: 2,
      retryDelay: attempt => attempt * 1000,
    },
  },
});

export default function Root() {
  return (
    <PrivateRender mode={process.env.NODE_ENV}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider resetCSS theme={theme}>
          <Fonts />
          <App />
          <ToastContainer />
        </ChakraProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </PrivateRender>
  );
}
