/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
import { useDisclosure } from '@chakra-ui/react';
import { HeaderBase } from '@react-ib-mf/header-menu-ui';
import { ToastComponent } from '@wow/toast';
import { useContext, useEffect, useState } from 'react';

import BottomNav from '../components/BottomNav';
import List from '../components/List';
import { ModalNotification } from '../components/ModalNotification';
import useChakraToast from '../hooks/useChakraToast';
import { IModalInformationDetails } from '../interfaces/IModalInformation';
import { IBanks } from '../interfaces/ViewBalance';
import messages from '../lang/es.json';
import { AppContext } from '../providers/AppContextProvider';
import compareObjectsByProp from '../utils';
import { SectionHeaderAndList, Wrapper } from './styles';

function VisualizarSaldos() {
  const appContext = useContext(AppContext) as any;

  const {
    banksOnlineFetched,
    dataIBanks,
    setDataIBanks,
    dataIBanksAPI,
    isFetchingIBanks,
    isErrorIBanks,
    enableBanksMutation,
    isEnablingBanks,
  } = appContext;
  const [selectedBanksToEnable, setSelectedBanksToEnable] = useState<IBanks[]>([]);
  const [selectedBanksToDisable, setSelectedBanksToDisable] = useState<IBanks[]>([]);

  const [modalNotificationInformation, setModalNotificationInformation] = useState<IModalInformationDetails>({
    title: '',
    subtitle: '',
    cancelButton: '',
    acceptButton: '',
  });

  const [isDisabledModalAcceptButton, setIsDisabledModalAcceptButton] = useState<boolean>(false);

  const { showToast, setIsActiveToast } = useChakraToast();

  const handleCheckboxChange = index => {
    const selectAllCheckbox = index === -1;

    if (selectAllCheckbox) {
      const allBanksEnabled = dataIBanks.every(bank => bank.isEnable);
      const updatedBanks = dataIBanks.map(bank => ({ ...bank, isEnable: !allBanksEnabled }));

      setDataIBanks(updatedBanks);
    } else {
      setDataIBanks(prevBanks => prevBanks.map((bank, i) => (i === index ? { ...bank, isEnable: !bank.isEnable } : bank)));
    }
  };

  useEffect(() => {
    const updatedBanks = dataIBanks.filter(
      (changedBank, indexFilter) =>
        !dataIBanksAPI.some(
          (unchangedBank, indexSome) => indexFilter === indexSome && compareObjectsByProp(unchangedBank, changedBank, 'isEnable'),
        ),
    );

    const banksToEnable = [];
    const banksToDisable = [];

    updatedBanks.forEach(bank => (bank.isEnable ? banksToEnable.push(bank) : banksToDisable.push(bank)));

    setSelectedBanksToEnable(banksToEnable);
    setSelectedBanksToDisable(banksToDisable);
  }, [dataIBanks]);

  const isAnyBankToEnable = Boolean(selectedBanksToEnable.length);

  const isAnyBankToDisable = Boolean(selectedBanksToDisable.length);

  const isAnyBankSelected = isAnyBankToEnable || isAnyBankToDisable;

  const handleSavingSelectedBanks = () => {
    if (isAnyBankToDisable && !isAnyBankToEnable) {
      setModalNotificationInformation({
        title: messages.modalComponentMinusBank.title,
        subtitle: messages.modalComponentMinusBank.subtitle,
        cancelButton: messages.modalComponentMinusBank.cancelButton,
        acceptButton: messages.modalComponentMinusBank.acceptButton,
      });

      setIsDisabledModalAcceptButton(false);
    }

    if (isAnyBankToEnable) {
      setModalNotificationInformation({
        title: messages.modalComponent.title,
        subtitle: messages.modalComponent.subtitle,
        bodyText: messages.modalComponent.text,
        checkboxText: messages.modalComponent.checkbox,
        cancelButton: messages.modalComponent.cancelButton,
        acceptButton: messages.modalComponent.acceptButton,
      });

      setIsDisabledModalAcceptButton(true);
    }

    onOpenModalNotification();
  };

  const { isOpen: isOpenModalNotification, onOpen: onOpenModalNotification, onClose } = useDisclosure();

  const handleModalCheckboxChange = () => {
    setIsDisabledModalAcceptButton(!isDisabledModalAcceptButton);
  };

  const saveBanksSelected = async () => {
    setIsActiveToast(true);
    let isSuccessfulEnableBank = true;

    const { cuit } = dataIBanksAPI[0];
    const enableBankCodBcraList = [];
    dataIBanks.forEach(bank => {
      if (bank.isEnable) {
        enableBankCodBcraList.push(bank.codBcra);
      }
    });

    try {
      isSuccessfulEnableBank = true;
      await enableBanksMutation({ cuit, codBcraList: enableBankCodBcraList });
    } catch (error) {
      isSuccessfulEnableBank = false;
    }

    showToast({
      status: isSuccessfulEnableBank ? 'success' : 'error',
      description: isSuccessfulEnableBank ? messages.toast.successDescription : messages.toast.errorDescription,
      render: props => <ToastComponent {...props} />,
    });

    setIsActiveToast(false);
    onClose();
  };

  return (
    <Wrapper>
      <SectionHeaderAndList>
        <HeaderBase title={messages.title} description={messages.description} breadcrumb={messages.breadcrumbOptions} />
        <List
          handleCheckboxChange={(index: number) => handleCheckboxChange(index)}
          dataIBanks={dataIBanks}
          isFetchingIBanks={isFetchingIBanks}
          isErrorIBanks={isErrorIBanks}
        />
      </SectionHeaderAndList>

      <BottomNav isAnyBankSelected={isAnyBankSelected} onClickIsSaved={handleSavingSelectedBanks} />

      <ModalNotification
        InformationDetails={modalNotificationInformation}
        isOpen={isOpenModalNotification}
        onClose={onClose}
        handleCheckboxChange={handleModalCheckboxChange}
        isDisabledAcceptButton={isDisabledModalAcceptButton}
        isButtonLoading={isEnablingBanks}
        handleClickAccept={saveBanksSelected}
      />
    </Wrapper>
  );
}
export default VisualizarSaldos;
