/* eslint-disable react/no-array-index-key */
import { Avatar, Checkbox } from '@chakra-ui/react';

import { IBanksItemList } from '../../../interfaces/ViewBalance';
import { TitleItemList, TitleItemListAllBanks, TitleName, WrapperItemList } from './styled';

function ItemList({ dataIBanks, handleCheckboxChange }: IBanksItemList) {
  return (
    <div style={{ width: '100%' }} data-testid='ItemList'>
      <WrapperItemList>
        {dataIBanks.length > 1 && (
          <div key={-1} data-testid='ItemListAllBanks'>
            <TitleItemListAllBanks>
              <Checkbox
                isChecked={dataIBanks.every(bank => bank.isEnable)}
                onChange={() => handleCheckboxChange(-1)}
                data-testid='Checkbox'
              />
              <TitleName>Todos los bancos</TitleName>
            </TitleItemListAllBanks>
          </div>
        )}

        {dataIBanks.map((bank, index) => (
          <div key={index} data-testid='ItemListTitle'>
            <TitleItemList>
              <Checkbox isChecked={dataIBanks[index].isEnable} onChange={() => handleCheckboxChange(index)} data-testid='Checkbox-index' />
              <Avatar name={bank.nameBank} size='sm' style={{ marginLeft: '20px' }} />
              <TitleName>{bank.nameBank}</TitleName>
            </TitleItemList>
          </div>
        ))}
      </WrapperItemList>
    </div>
  );
}

export default ItemList;
