/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { IBanks } from '../interfaces/ViewBalance';
import { enableBanks, getBanks } from '../services/api';

export const AppContext = createContext({});
export default function AppContextProvider({ children }: any) {
  const [banksOnlineFetched, setBanksOnlineFetched] = useState(false);
  const [dataIBanks, setDataIBanks] = useState<IBanks[]>([]);

  const {
    data: dataIBanksAPI,
    isFetching: isFetchingIBanks,
    isError: isErrorIBanks,
    refetch: refetchGetBanks,
  } = useQuery(['GetBanks'], async () => {
    const response = await getBanks();
    setDataIBanks(response.data.data);
    return response.data.data;
  });

  const getBanksProvider = () => {
    if (dataIBanksAPI) {
      setBanksOnlineFetched(true);
      return dataIBanksAPI;
    } else {
      setBanksOnlineFetched(false);
    }
  };

  const { mutateAsync: enableBanksMutation, isLoading: isEnablingBanks } = useMutation(async payload => enableBanks(payload), {
    onSuccess: () => {
      refetchGetBanks();
    },
  });

  const value = useMemo(
    () => ({
      getBanksProvider,
      banksOnlineFetched,
      dataIBanks,
      setDataIBanks,
      dataIBanksAPI,
      isFetchingIBanks,
      isErrorIBanks,
      enableBanksMutation,
      isEnablingBanks,
    }),
    [
      getBanksProvider,
      banksOnlineFetched,
      dataIBanks,
      setDataIBanks,
      dataIBanksAPI,
      isFetchingIBanks,
      isErrorIBanks,
      enableBanksMutation,
      isEnablingBanks,
    ],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
