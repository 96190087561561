// eslint-disable-next-line import/no-unresolved
import { ErrorPermissionContent } from '@react-ib-mf/style-guide-ui';
import { useQuery } from 'react-query';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import AppContainer from './layouts/AppContainer';
import AppContextProvider from './providers/AppContextProvider';
import { getRoleValidate } from './services/api';
import VisualizarSaldos from './views/VisualizarSaldos';

function App() {
  const { data: isAdminGeneral, isLoading, isError } = useQuery(['getRoleValidate'], () => getRoleValidate());

  if (isLoading) {
    return <div />;
  }
  return (
    <AppContextProvider>
      <Router>
        <Routes>
          <Route path='/' element={<AppContainer />}>
            {!isAdminGeneral?.data || isError ? (
              <Route path='/visualizar-saldos' element={<ErrorPermissionContent />} />
            ) : (
              <Route path='/visualizar-saldos' element={<VisualizarSaldos />} />
            )}
          </Route>
        </Routes>
      </Router>
    </AppContextProvider>
  );
}

export default App;
