import { Skeleton, Stack } from '@chakra-ui/react';

import imgEmptyState from '../../assets/images/Binoculars.png';
import { IBanksList } from '../../interfaces/ViewBalance';
import messages from '../../lang/es.json';
import ScreenError from '../ScreenError/ScreenError';
import ItemList from './ItemList';

function List({ handleCheckboxChange, dataIBanks, isFetchingIBanks, isErrorIBanks }: IBanksList) {
  return (
    <div style={{ width: '100%' }} data-testid='divList'>
      {isFetchingIBanks && (
        <Stack width='100%' spacing={2} marginTop={6} data-testid='Stack'>
          {Array.from({ length: 5 }, (_, index) => (
            <Skeleton maxWidth='605px' height='56px' key={index} data-testid='Skeleton' />
          ))}
        </Stack>
      )}

      {!isFetchingIBanks && isErrorIBanks && (
        <ScreenError
          title={messages.screenErrorText.title}
          description={messages.screenErrorText.description}
          image={imgEmptyState}
          imageALt={messages.screenErrorText.alt}
          textButton={messages.screenErrorText.textButton}
          data-testid='ScreenError'
        />
      )}

      {!isFetchingIBanks && !isErrorIBanks && dataIBanks && (
        <ItemList handleCheckboxChange={index => handleCheckboxChange(index)} dataIBanks={dataIBanks} data-testid='ItemList' />
      )}
    </div>
  );
}

export default List;
