import styled from 'styled-components';

const WrapperItemList = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  padding: 16px 0px;
`;

const TitleItemListAllBanks = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 16px;
  border-bottom: 1px solid;
  border-color: #c7c6ca;
`;

const TitleItemList = styled.div`
  width: 86%;
  display: flex;
  flex-direction: row;
  padding: 16px;
  border-bottom: 1px solid;
  border-color: #c7c6ca;
`;

const TitleName = styled.div`
  margin-left: 14px;
  margin-top: 4px;
  font-family: 'Red Hat Display';
  font-weight: 700;
  font-size: 18px;
`;

export { TitleItemList, TitleItemListAllBanks, TitleName, WrapperItemList };
