/* eslint-disable dot-notation */
/* eslint-disable import/no-unresolved */
import { getToken } from '@react-ib-mf/style-guide-ui';
import axios from 'axios';

import mockDataBanks from '../__mocks__/VisualizarSaldos';
import { IResponseData } from '../interfaces/IResponseData';
import { IBanks } from '../interfaces/ViewBalance';
import getTokenForSessionStorage from '../utils/sesionStorage';

const axiosInstance = axios.create({
  headers: {
    Authorization: `Bearer ${getToken()}`,
    'x-jwt-assertion': getTokenForSessionStorage(),
    'Content-Type': 'application/json',
  },
});

const getBanks = () => {
  axiosInstance.defaults.headers['user_key'] = process.env.API_USER_KEY_VISUALIZAR_SALDOS;
  return axiosInstance.get(`${process.env.VISUALIZAR_SALDOS_BFF_BASE_URL}/banks`);
};

const enableBanks = payload => {
  axiosInstance.defaults.headers['user_key'] = process.env.API_USER_KEY_VISUALIZAR_SALDOS;
  return axiosInstance.post(`${process.env.VISUALIZAR_SALDOS_BFF_BASE_URL}/banks`, payload);
};

const getBanksMock = () => {
  return new Promise<IResponseData<IBanks[]>>(resolve => {
    setTimeout(() => {
      return resolve(mockDataBanks);
    }, 3000);
  });
};

const getRoleValidate = () => {
  axiosInstance.defaults.headers['user_key'] = process.env.API_USER_KEY_VISUALIZAR_SALDOS;
  return axiosInstance.get(`${process.env.VISUALIZAR_SALDOS_BFF_BASE_URL}/roles`);
};

export { enableBanks, getBanks, getBanksMock, getRoleValidate };
