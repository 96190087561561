import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  height: 85vh;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 20px;
`;

const SectionHeaderAndList = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
`;

export { SectionHeaderAndList, Wrapper };
