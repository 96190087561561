/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

import { COLOR_TITLE_SCREEN_ERROR } from '../../designTokens/colors';
import { FONT_DESCRIPTION_SCREEN_ERROR, FONT_OPTION_DROPDOWN_REPORTS, FONT_TITLE_SCREEN_ERROR } from '../../designTokens/fonts';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  margin-top: 30px;

  .image {
    margin-bottom: 28px;
  }
  .title {
    font-family: ${FONT_TITLE_SCREEN_ERROR.fontFamily};
    font-weight: ${FONT_TITLE_SCREEN_ERROR.fontWeight};
    font-size: ${FONT_TITLE_SCREEN_ERROR.fontSize};
    color: ${COLOR_TITLE_SCREEN_ERROR};
    margin-bottom: 8px;
    p {
      margin: 0;
    }
  }
  .description {
    font-family: ${FONT_DESCRIPTION_SCREEN_ERROR.fontFamily};
    font-weight: ${FONT_DESCRIPTION_SCREEN_ERROR.fontWeight};
    font-size: ${FONT_DESCRIPTION_SCREEN_ERROR.fontSize};
    color: ${COLOR_TITLE_SCREEN_ERROR};
  }
  button span {
    font-size: ${FONT_OPTION_DROPDOWN_REPORTS.fontSize} !important;
  }
`;

export { Container };
