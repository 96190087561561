/* eslint-disable no-restricted-globals */
// eslint-disable-next-line import/no-unresolved
import { TextButton } from '@react-ib-mf/style-guide-ui';

import { IScreenError } from '../../interfaces/IScreenError';
import { Container } from './styled';

function ScreenError(props: IScreenError) {
  const { title, description, image, imageALt, textButton } = props;
  function reloadPage() {
    location.reload();
  }
  return (
    <Container>
      <div className='image'>
        <img data-testid='screen-Err-img' src={image} alt={imageALt} />
      </div>
      <div className='title'>
        <p data-testid='screen-Err-title'>{title}</p>
      </div>
      <div className='description' data-testid='screen-Err-desc'>
        {description}
      </div>
      <div style={{ marginTop: '32px' }}>
        <TextButton text={textButton} onClick={() => reloadPage()} />
      </div>
    </Container>
  );
}

export default ScreenError;
