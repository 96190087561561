/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 72px;
  margin-left: -120px;
  box-shadow: 0px -3px 8px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 2;

  .heightGrid {
    height: 72px;
    display: flex;
    justify-content: end;
  }

  .buttonRight {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 64px;
  }

  .buttonLeft {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 100px;
    :focus {
      background: none;
    }
  }
  }
`;

export { Wrapper };
