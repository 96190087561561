/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/no-duplicates */

import { Button, Grid, Icon } from '@chakra-ui/react';
import { ChevronRight } from 'react-feather';

import { IBottomNav } from '../../interfaces/ViewBalance';
import { Wrapper } from './styled';

function BottomNav({ onClickIsSaved, isAnyBankSelected }: IBottomNav) {
  return (
    <Wrapper>
      <Grid templateColumns='repeat(12, 1fr)' columnGap={16} rowGap={0} className='heightGrid'>
        <div className='buttonRight' data-testid='buttonRight'>
          <Button size='md' isDisabled={!isAnyBankSelected} onClick={onClickIsSaved} rightIcon={<Icon as={ChevronRight} />}>
            Guardar
          </Button>
        </div>
      </Grid>
    </Wrapper>
  );
}

export default BottomNav;
