/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import { Button, Checkbox, CloseButton, Text } from '@chakra-ui/react';
import { Modal, ModalBody, ModalChakra, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@wow/modal';
import PropTypes from 'prop-types';

import { IModalNotification } from '../../interfaces/IModalInformation';
import ModalSubtitle from './styled';

ModalNotification.propTypes = {
  InformationDetails: PropTypes.oneOfType([PropTypes.object]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
  isDisabledAcceptButton: PropTypes.bool,
  isButtonLoading: PropTypes.bool,
  handleClickAccept: PropTypes.func,
};

export function ModalNotification(props: IModalNotification) {
  const {
    InformationDetails,
    isOpen,
    onClose,
    handleCheckboxChange,
    isDisabledAcceptButton,
    isButtonLoading,
    handleClickAccept,
  }: IModalNotification = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant='states' id='ModalNotification' size='xl' data-testid='ModalNotification'>
      <ModalOverlay />
      <ModalContent id='ModalContent' style={{ paddingBottom: '16px' }} data-testid='ModalContent'>
        <CloseButton onClick={onClose} id='CloseButton' data-testid='CloseButton' sx={ModalChakra.close} />

        <ModalHeader state='info' id='ModalHeader' data-testid='ModalHeader'>
          <Text textStyle='h3'>{InformationDetails.title}</Text>
        </ModalHeader>
        <ModalSubtitle id='ModalSubtitle' data-testid='ModalSubtitle'>
          <Text textStyle='bodyMd'>{InformationDetails.subtitle}</Text>
        </ModalSubtitle>

        {InformationDetails.bodyText && (
          <ModalBody id='ModalBody' data-testid='ModalBody'>
            <Text textStyle='labelXs' style={{ paddingBottom: '10px', margin: '0px 30px', textAlign: 'justify' }}>
              {InformationDetails.bodyText}
            </Text>
            {InformationDetails.checkboxText && (
              <Checkbox
                style={{ alignSelf: 'center', fontSize: '14px' }}
                onChange={handleCheckboxChange}
                id='CheckBox'
                data-testid='CheckBox'
              >
                <Text textStyle='bodyMd'>{InformationDetails.checkboxText}</Text>
              </Checkbox>
            )}
          </ModalBody>
        )}

        <ModalFooter id='ModalFooter' data-testid='ModalFooter'>
          <Button variant='primary-outline' onClick={onClose} id='cancelButton' data-testid='cancelButton'>
            {InformationDetails.cancelButton}
          </Button>
          <Button
            isLoading={isButtonLoading}
            loadingText={InformationDetails.acceptButton}
            variant='primary'
            onClick={handleClickAccept}
            isDisabled={isDisabledAcceptButton}
            id='acceptButton'
            data-testid='acceptButton'
          >
            {InformationDetails.acceptButton}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
